
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList } from '@ionic/vue';
import { useRoute,onBeforeRouteLeave } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import MessageField from '@/views/MessageField.vue';

export default {
	name: 'Topic',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		MessageField
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : '';
		}
	},
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || 'Inbox');
    const matchedFolder = computed(() => route.params.id);
	let chkTimer: number;
	const { token, getRichText, doLike } = useDataService();
    
	watch(route, () => {
		clearTimeout(chkTimer);
	});
	
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });
	
	const comments = ref<any>([]);
	const post = ref(null);
	let gettingNew = false;
	
	const playOk = function(){
		const audio = new Audio('/sounds/notify.wav');
		audio.play();
	};
	
	const getNew = function(){
		if(!gettingNew){
			gettingNew = true;
			const lastmsg = comments.value[comments.value.length-1].id;
			axios.get('https://beon.fun/api/v1/topic/'+route.params.id+'/get?last='+lastmsg,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then(response => (
				response.data.forEach((item: any) => {comments.value.push(item)}),
				playOk()
			)).finally(() => (gettingNew = false));
		}
	};
	
	const checkNew = function(){
		const lastmsg = comments.value[comments.value.length-1].id;
		axios.get('https://beon.fun/api/v1/topic/'+route.params.id+'/check?last='+lastmsg,{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then((response) => {
			if(response.data.new == 1){
				getNew();
			}
		});
	};

	axios.get('https://beon.fun/api/v1/topic/'+route.params.id,{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
		response.data.replies.forEach((itm: any)=>{comments.value.push(itm)}),
		post.value = response.data.topic,
		folder.value =  response.data.topic.title,
		chkTimer = setInterval(checkNew, 5000)
	));
	
	const sendpoint = 'https://beon.fun/api/v1/topic/'+route.params.id;
	const passquote = ref<string>("");
	const getQuote = function(e: any){
		passquote.value = "\r\n" + e.currentTarget.parentNode.childNodes[0].innerText.replace(/^/g,'> ').replace(/\n/g,'\n> ');
	};
    return { folder, comments, post, sendpoint, passquote, getQuote, getNew, getRichText, doLike }
  }
}
